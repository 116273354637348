import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {CartIconApp} from './components/CartIconApp/CartIconApp';
import {CART_ICON_DSN} from '@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';

export const component = withSentryErrorBoundary(
  withStyles(withStrictMode(CartIconApp), {cssPath: `cartIcon${!process.env.IS_MINIFIED ? '' : '.min'}.css`}),
  {
    dsn: CART_ICON_DSN,
    config: {environment: 'Native Component'},
  }
);
