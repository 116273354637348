import React from 'react';
import styles from './SvgIcon.scss';
import {IconProps} from '../Icons/Icons';

export interface ISvgIconProps extends IconProps {
  viewBox: string;
  preserveAspectRatio: boolean;
}

interface ISvgIconState {
  isVisible: boolean;
}

export class SvgIcon extends React.PureComponent<ISvgIconProps, ISvgIconState> {
  constructor(props: ISvgIconProps) {
    super(props);
    this.state = {
      isVisible: !!props.aspectRatio,
    };

    this.resizeFrame = this.resizeFrame.bind(this);
  }

  public componentDidMount() {
    setTimeout(this.resizeFrame, 0);
  }

  public componentDidUpdate(prevProps: ISvgIconProps) {
    if (this.props.text !== prevProps.text) {
      this.setState({isVisible: false}, this.resizeFrame);
    }
  }

  private resizeFrame() {
    const height = this.props.containerHeight;
    const proportion = this.calculateProportion();
    const width = Math.round((height * 100) / proportion);
    this.props
      .resizeComponent(width)
      .then(() => this.setState({isVisible: true}))
      .catch(() => this.setState({isVisible: true}));
  }

  public render() {
    const proportion = this.calculateProportion();
    const preserveAspectRatio = this.props.preserveAspectRatio ? {preserveAspectRatio: 'xMinYMax meet'} : {};
    return (
      <div
        className={styles.ie11Hack}
        style={{paddingBottom: `${proportion}%`, visibility: this.state.isVisible ? 'visible' : 'hidden'}}
        data-hook="svg-icon-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="100%"
          viewBox={this.props.viewBox}
          {...preserveAspectRatio}
          data-hook={`svg-icon-${this.props.iconId}`}>
          {this.props.children}
        </svg>
      </div>
    );
  }

  private calculateProportion() {
    const [, , vbWidth, vbHeight] = this.props.viewBox.split(' ');

    return (parseFloat(vbHeight) / parseFloat(vbWidth)) * 100;
  }
}
